import React, { Component } from "react";
import Zmage from "react-zmage";

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;
    console.log(this.props);
    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={projects.title} src={projectImage} />
            <div style={{ textAlign: "center" }}>
              <a target="_blank" href={projects.url} rel="noreferrer">
                {projects.title}
              </a>
            </div>
          </div>
        </div>
      );
    });
    const clientworks = this.props.clientdata.work.map(function (clientwork) {
      let clientworkImage = "images/clientwork/" + clientwork.image;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={clientwork.title} src={clientworkImage} />
            <div style={{ textAlign: "center" }}>
              <a target="_blank" href={clientwork.url} rel="noreferrer">
                {clientwork.title}
              </a>
            </div>
          </div>
        </div>
      );
    });
    return (
      <>
        <section id="portfolio">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </section>
        <section id="clientworks">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="clientworks-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {clientworks}
              </div>
            </div>
          </div>
        </section>
      </>
    );
    // return<></>
  }
}

export default Portfolio;
